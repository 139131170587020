




































































































































































































































































































































































import Vue from "vue";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import { Banner, Item, ItemType } from "@/store/types";
import { mapGetters } from "vuex";
import { getCssValue, getHexColor, rgbaTo32Bit } from "@/utils"

export default Vue.extend({
  name: "BannerEditor",

  components: {
    FullScreenDialog,
  },

  data: () => ({
    scale: 4,
    imageTypes: [ItemType.BG_IMAGE, ItemType.IMAGE],
    itemType: ItemType,
    fullScreenDialog: false,
    editingBgColor: false,
    saving: false,
    color: {
      r: 1,
      g: 2,
      b: 1,
      a: 2
    },
    select: [
      { text: "Sim", value: true },
      { text: "Não", value: false }
    ],
    aligments: [
      { text: "Esquerda", value: 'TextAlign.left' },
      { text: "Centro", value: 'TextAlign.center' },
      { text: "Direita", value: 'TextAlign.right' },
      { text: "Justificado", value: 'TextAlign.justify' },
    ],
    weights: [
      { text: "Normal", value: 'FontWeight.w400' },
      { text: "Bold", value: 'FontWeight.w700' },
    ],
    decorations: [
      { text: "Nenhum", value: 'TextDecoration.none' },
      { text: "Riscado", value: 'TextDecoration.lineThrough' },
      { text: "Overline", value: 'TextDecoration.overline' },
      { text: "Underline", value: 'TextDecoration.underline' },
    ],
    styles: [
      { text: "Normal", value: 'FontStyle.normal' },
      { text: "Itálico", value: 'FontStyle.italic' },
    ],
    fontFamilies: [
      { text: "Avenir", value: 'Avenir' },
      { text: "Berkshire Swash", value: 'Berkshire Swash' },
      { text: "Rubik Dirt", value: 'Rubik Dirt' },
      { text: "OpenSans", value: 'OpenSans' },
      { text: "Pacifico", value: 'Pacifico' },
      { text: "Paytone One", value: 'Paytone One' },
      { text: "Permanent Marker", value: 'Permanent Marker' },
      { text: "Righteous", value: 'Righteous' },
      { text: "Roboto", value: 'Roboto' },
      { text: "Roboto Slab", value: 'Roboto Slab' },
      { text: "Rubik Marker Hatch", value: 'Rubik Marker Hatch' },
      { text: "Titan One", value: 'Titan One' },
      { text: "Ubuntu", value: 'Ubuntu' },
      { text: "Ultra Regular", value: 'Ultra Regular' },
    ],
    textReferences: [
      { text: "Nenhum", value: '' },
      { text: "Nome da Oferta", value: 'offer.name' },
      { text: "Preço Atual", value: 'offer.price' },
      { text: "Preço Anterior", value: 'offer.price_from' },
      { text: "Parcelamento", value: 'offer.installment' },
      { text: "SKU", value: 'offer.sku' },
      { text: "Nome do Vendedor", value: 'seller.name' },
      { text: "WhatsApp", value: 'seller.whatsapp' },
      { text: "Instagram", value: 'seller.instagram' },
      { text: "Facebook", value: 'seller.facebook' },
    ],
    imageReferences: [
      { text: "Nenhum", value: '' },
      { text: "Foto da Oferta", value: 'offer.thumbnail' },
    ],
    versions: [
      { text: "V1", value: 'v1' },
      { text: "V2", value: 'v2' },
    ],
    payload: {
      name: "Banner 123",
      user_generated: false,
      editor_version: "v2",
      height: 1920,
      width: 1080,
      ratio_height: 16,
      ratio_width: 9,
      tags: [],
      active: true,
      items: [],
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    } as Banner,
    selectedItem: {} as Item | null
  }),

  computed: {
    ...mapGetters({
      banner: "bannersModule/getBanner",
    }),
    getScale(): number {
      return this.scale / 10;
    },
  },

  watch: {
    banner() {
      this.payload = Object.assign({}, this.banner)
    }
  },

  mounted() {
    this.payload = Object.assign({}, this.banner)
  },

  methods: {
    getAlignment(key = 'TextAlign.left'): string {
      const values = {
        'TextAlign.left': 'left',
        'TextAlign.center': 'center',
        'TextAlign.right': 'right',
        'TextAlign.justify': 'justify'
      }

      return getCssValue(values, key, 'left')
    },
    getWeight(key = 'FontWeight.w400'): string {
      const values = {
        'FontWeight.w400': 'normal',
        'FontWeight.w700': 'bold',
      }

      return getCssValue(values, key, 'normal')
    },
    getDecoration(key = 'TextDecoration.none'): string {
      const values = {
        'TextDecoration.none': 'none',
        'TextDecoration.lineThrough': 'line-through',
        'TextDecoration.overline': 'overline',
        'TextDecoration.underline': 'underline'
      }

      return getCssValue(values, key, 'none')
    },
    getStyle(key = 'FontStyle.normal'): string {
      const values = {
        'FontStyle.normal': 'none',
        'FontStyle.italic': 'italic',
      }

      return getCssValue(values, key, 'none')
    },
    getFontFamily(key = 'Avenir'): string {
      const values = {
        'Avenir': 'Avenir',
        'Berkshire Swash': 'Berkshire Swash',
        'Rubik Dirt': 'Rubik Dirt',
        'OpenSans': 'Open Sans',
        'Pacifico': 'Pacifico',
        'Paytone One': 'Paytone One',
        'Permanent Marker': 'Permanent Marker',
        'Righteous': 'Righteous',
        'Roboto': 'Roboto',
        'Roboto Slab': 'Roboto Slab',
        'Rubik Marker Hatch': 'Rubik Marker Hatch',
        'Titan One': 'Titan One',
        'Ubuntu': 'Ubuntu',
        'Ultra Regular': 'Ultra',
      }

      return getCssValue(values, key, 'Avenir')
    },

    getHexColor(color: number): string {
      return getHexColor(color);
    },

    clone(): void {
      const {_id, ...template} = this.payload;
      template.items = template.items.map(({ _id, ...item}) => item);
      template.name = `${template.name} (cópia)`;
      this.payload = template;
      this.$store.dispatch("app/showIsSuccess", 'Banner Clonado!');
    },

    async save(): Promise<void> {
      this.saving = true
      if (this.payload._id) {
        await this.$store.dispatch('bannersModule/update', this.payload)
      } else {
        await this.$store.dispatch('bannersModule/create', {
          ...this.payload,
          created_at: new Date(),
          updated_at: new Date(),
        })
      }
      this.saving = false
    },

    deleteItem(item: Item): void {
      this.payload.items = this.payload.items.filter(it => it !== item);
    },

    move(fronIndex: number, toIndex: number): void {
      const element = this.payload.items.splice(fronIndex, 1)[0]
      this.payload.items.splice(toIndex, 0, element);
    },

    toEditBgColor(color: string): void {
      const [r,g,b,a] = color.replace('rgba(', '').replace(')', '').split(',');
      this.color = {
        r: Number(r),
        g: Number(g),
        b: Number(b),
        a: Number(a)
      };
      this.editingBgColor = true;
      this.fullScreenDialog = true;
    },

    toEditItemColor(color: string, item: Item): void {
      const [r,g,b,a] = color.replace('rgba(', '').replace(')', '').split(',');
      this.color = {
        r: Number(r),
        g: Number(g),
        b: Number(b),
        a: Number(a)
      };
      this.selectedItem = item;
      this.fullScreenDialog = true;
    },

    closeDialog(): void {
      this.fullScreenDialog = false;
      this.editingBgColor = false;
      this.selectedItem = null;
    },

    changeColor(): void {
      const { r, g, b, a } = this.color;

      if(this.editingBgColor) {
        this.payload.background = rgbaTo32Bit(r, g, b, a);
      } else if (this.selectedItem?.type === ItemType.BG_COLOR) {
        this.selectedItem.content = rgbaTo32Bit(r, g, b, a);
      } else if (this.selectedItem?.type === ItemType.TEXT) {
        this.selectedItem.font_color = rgbaTo32Bit(r, g, b, a);
      }

     this.closeDialog();
    },

    addItem(item: Item): void {
      this.payload.items.push(item);
    },

    onAddImage(): void {
      this.addItem({
        position: { dx: 50, dy: 50 },
        type: ItemType.IMAGE,
        content: "https://placehold.co/700x700",
        locked: false,
        reference: "",
        height: 700,
        width: 700,
        rotate: 0,
      })
    },

    onAddText(): void {
      this.addItem({
        position: { dx: 20, dy: 20 },
        type: ItemType.TEXT,
        content: "",
        locked: false,
        reference: "",
        height: 200,
        width: 800,
        rotate: 0,
        font_size: 47,
        font_color: 4294967295,
        text_align: "TextAlign.left",
        font_weight: "FontWeight.w400",
        text_decoration: "TextDecoration.none",
        font_style: "FontStyle.normal",
        font_family: "Avenir",
      })
    },

    onAddColorItem(): void {
      this.addItem({
        position: { dx: 50, dy: 50 },
        type: ItemType.BG_COLOR,
        content: 4294967295,
        locked: false,
        reference: "",
        height: 600,
        width: 600,
        rotate: 0,
      },)
    },
  }
})
