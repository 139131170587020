<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container style="margin: 10px 0 0; padding: 0">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="name"
            label="Título"
            required
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="tags"
            label="Tag"
            required
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model="active"
            :items="options"
            item-text="text"
            item-value="value"
            label="Status"
          />
        </v-col>

        <v-col cols="12" md="4" style="margin-top: 20px">
          <v-btn
            small
            color="error"
            class="mr-4"
            @click="reset"
          >
            Limpar
          </v-btn>

          <v-btn
            small
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="handleFilter"
          >
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script lang="js">

export default {
  name: "BannersFilters",

  data: () => ({
    valid: true,
    name: null,
    tags: null,
    active: null,
    options: [
      { text: "Ativo", value: true },
      { text: "Inativo", value: false }
    ],
    checkbox: false,
  }),

  methods: {
    handleFilter () {
      const { name, tags, active } = this
      this.$store.commit("bannersModule/setFilters", { name, tags, active })
      this.$store.dispatch("bannersModule/fetch")
    },

    reset () {
      this.$refs.form.reset()
      this.$store.commit("bannersModule/setFilters", {})
      this.$store.dispatch("bannersModule/fetch")
    }
  },
}
</script>

<style scoped>

</style>
